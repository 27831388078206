import * as React from 'react';
import { Translate } from '@hanssens/ht-translate';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HTInput, HTSaveButton, HTSelect } from '@hanssens/ht-gui';
import { useHTStore } from '@hanssens/ht-store';
import { TextField } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import userApi from '../api/userApi';
import { ITicket } from '../interfaces/ITicket';
import { TOPDESK_DEFAULT_URI } from '../config/config';
import { testPhoneNumber } from '../utilities/phoneUtil';

interface INewCallbackRequestProps {
    callTypes: ICallType[] | null;
}

export interface ICallType {
    id: string | null;
    name: string | null;
}

interface IHTSelectOption {
    value: string | null;
    label: string | null;
}

interface IOperator {
    id: string | null;
    name: string | null;
}

const debounceFunction = () => {
    return;
};

const debounce = AwesomeDebouncePromise(debounceFunction, 300);

const NewCallbackRequest: React.FC<INewCallbackRequestProps> = ({ callTypes }) => {
    /***********************************************************************
     * State
     ***********************************************************************/

    const [newTicket, setNewTicket] = useHTStore<ITicket | null>('newCallback');
    const [operators, setOperators] = useHTStore<IOperator[] | null>('callbackOperators');
    const [error, setError] = React.useState<boolean>(false);

    const navigate = useNavigate();

    /***********************************************************************
     * Helper functions
     ***********************************************************************/
    const trimEnd = (input: string, char: string) => {
        // Hold string while we modify it
        var tempString = input;

        // Keep going while the string ends with the input
        while (tempString.endsWith(char)) {
            // Remove last char
            tempString = tempString.slice(0, -1);
        }

        // Return modified string
        return tempString;
    };

    /***********************************************************************
     * Event handlers
     ***********************************************************************/
    const handleBackClicked = () => {
        navigate(-1);
    };

    const onTicketChanged = (prop: string, value: string | null) => {
        if (!newTicket) {
            return;
        }

        setNewTicket({
            ...newTicket,
            [prop]: value,
        });
    };

    const onPhoneNumberChanged = (value: string | null) => {
        if (value && !testPhoneNumber(value)) {
            return;
        }

        onTicketChanged('phoneNumber', value);
    };

    const handleOperatorInputChanged = React.useCallback(
        async (value: string) => {
            if (!newTicket) {
                return;
            }

            let input = value;
            if (!input) {
                setNewTicket({
                    ...newTicket,
                    operatorId: null,
                });
                return;
            }

            await debounce();
            userApi.get('ticket/operator', { params: { name: input } }).then((response) => {
                setOperators(response.data);
            });
        },
        [newTicket, setNewTicket, setOperators]
    );

    const handleSaveClicked = (onSuccess: () => void, onError: () => void) => {
        if (
            !newTicket ||
            !newTicket.firstName ||
            !newTicket.surName ||
            !newTicket.briefDescription ||
            !newTicket.description ||
            !newTicket.callTypeId ||
            !newTicket.operatorId ||
            !newTicket.phoneNumber
        ) {
            setError(true);
            onError();
            return;
        }
        userApi
            .post('ticket', newTicket)
            .then((response) => {
                onSuccess();
                window.open(`${trimEnd(TOPDESK_DEFAULT_URI, '/')}/tas/secure/contained/incident?unid=${response.data.id}`, '_blank');
                setNewTicket(null);
                navigate(-1);
            })
            .catch((err) => {
                onError();
                console.log('err', err);
            });
    };

    /***********************************************************************
     * Render
     ***********************************************************************/
    if (!newTicket) {
        return null;
    }

    let callTypeOptions: IHTSelectOption[] = [];
    if (callTypes) {
        callTypeOptions = callTypes.map((ct) => {
            return {
                value: ct.id,
                label: ct.name,
            };
        });
    }

    let operatorOptions: IHTSelectOption[] = [];
    if (operators) {
        operatorOptions = operators.map((o) => {
            return {
                value: o.id,
                label: o.name,
            };
        });
    }

    return (
        <div className='p-3'>
            <Button variant='contained' className='rounded-0' onClick={handleBackClicked}>
                <i className='fas fa-chevron-left me-2' />
                <Translate id='main.goBack' />
            </Button>
            <div className='mt-4 w-50'>
                <span className='fw-bold'>
                    <Translate id='main.newCallback' />:
                </span>
                <HTInput
                    className='my-3'
                    label={<Translate id='main.firstname' />}
                    value={newTicket.firstName}
                    onChange={(e, v) => onTicketChanged('firstName', v)}
                    error={error && !newTicket.firstName}
                    required
                    disabled={!!newTicket.callerId}
                />
                <HTInput
                    className='mb-3'
                    label={<Translate id='main.surname' />}
                    value={newTicket.surName}
                    onChange={(e, v) => onTicketChanged('surName', v)}
                    error={error && !newTicket.surName}
                    required
                    disabled={!!newTicket.callerId}
                />
                <HTInput
                    className='mb-3'
                    label={<Translate id='main.phoneNumber' />}
                    value={newTicket.phoneNumber}
                    onChange={(e, v) => onPhoneNumberChanged(v)}
                    error={error && !newTicket.phoneNumber}
                    required
                    disabled={!!newTicket.callerId && !!newTicket.phoneNumber && newTicket.phoneNumber !== 'anonymous'}
                />

                <HTSelect
                    id='callType'
                    className='mb-3'
                    type='select'
                    label={<Translate id='main.callType' />}
                    value={newTicket.callTypeId}
                    options={callTypeOptions}
                    onChange={(v) => onTicketChanged('callTypeId', v)}
                    error={error && !newTicket.callTypeId}
                    required
                />
                <HTSelect
                    id='operator'
                    className='mb-3'
                    type='async-select'
                    label={<Translate id='main.operator' />}
                    value={newTicket.operatorId}
                    onInputChange={handleOperatorInputChanged}
                    options={operatorOptions}
                    onChange={(v) => onTicketChanged('operatorId', v)}
                    error={error && !newTicket.operatorId}
                    required
                />

                <HTInput
                    className='mb-3'
                    label={<Translate id='main.briefDescription' />}
                    value={newTicket.briefDescription}
                    onChange={(e, v) => onTicketChanged('briefDescription', v)}
                    error={error && !newTicket.briefDescription}
                    required
                />
                <TextField
                    variant='outlined'
                    className='w-100 mb-3 texfield-rounded-0'
                    label={<Translate id='main.description' />}
                    multiline
                    minRows={5}
                    value={newTicket.description}
                    onChange={(e) => onTicketChanged('description', e.currentTarget.value)}
                    error={error && !newTicket.description}
                    required
                />

                <HTSaveButton
                    type='default'
                    neutralLayout={{
                        hideCancel: true,
                    }}
                    onCancel={() => {}}
                    onSave={handleSaveClicked}
                    showFeedback
                />
            </div>
        </div>
    );
};

export default NewCallbackRequest;
