import axios from 'axios';

// const baseApiUrl = 'http://localhost:5002/';
// const baseApiUrl = 'https://evergem.dashboard.hanssens.io/';
const baseApiUrl = 'https://demo.dashboard.hanssens.io/';

let url = baseApiUrl + 'api/v1/';

const instance = axios.create({
    baseURL: url,
});

// instance.CancelToken = axios.CancelToken;
// instance.isCancel = axios.isCancel;

export default instance;
