import * as React from 'react';

import MainRouter from '../routers/MainRouter';

// Css for icons
import '@fortawesome/fontawesome-pro/css/all.css';
import { useTranslations } from '@hanssens/ht-translate';

const App = () => {
    const { setCurrentLanguage } = useTranslations();

    React.useEffect(() => {
        setCurrentLanguage('en');
        // const userLang = navigator.language;
        // if (userLang.includes('en')) {
        //     setCurrentLanguage('en');
        // } else {
        //     setCurrentLanguage('nl');
        // }
    }, [setCurrentLanguage]);

    return <MainRouter />;
};

export default App;
